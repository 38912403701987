<template>
    <div data-scroll
         :data-scroll-id="name"
         class="layout-5">
        <div v-observer :class="{dark:lightMode}" class="content-container">
            <div class="text-content">
                <header-title :name="item.title" :text-color="headerColor" underline-color="#93272C"/>
                <h2 :style="{color:item.color}" class="title">{{ item.contentTitle }}</h2>
                <div class="description" v-html="item.contentText"></div>
            </div>
            <div class="image-container">
                <img :src="item.image" data-scroll
                     :data-scroll-speed="imageSpeed" class="image" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import first from 'lodash/first'
import HeaderTitle from "@/components/custom/HeaderTitle";
import {mapGetters} from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        config: {
            type: Object,
            default: () => {
            }
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        },
    },
    components: {
        HeaderTitle
    },
    computed: {
        ...mapGetters({
            device: 'resize/geDevice'
        }),
        item() {
            return first(this.data?.list?.map(item => ({
                background: item.background_color,
                image: first(item?.image)?.devices?.[this.device?.type],
                title: item.title,
                contentTitle: first(item?.content?.tabs)?.title,
                contentText: first(item?.content?.tabs)?.content,
                color: item.color1
            })))
        },
        imageSpeed() {
            return this.device.size < 1024 ? 0 : 1.4
        },
        headerColor() {
            return this.lightMode ? '#0A141C' : 'white'
        }
    },

}
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(.15, .16, .37, 1);

.layout-5 /deep/ {
    padding: 144px 195px 0;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding: 144px 165px 0;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        padding: 144px 128px 0;
    }
    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        padding: 89px 47px 0;
    }
    @media only screen and (max-width: 767px) {
        padding: 55px 16px 0;
    }

    .content-container {
        display: flex;
        align-items: center;
        grid-gap: 150px;
        font-size: 21px;
        color: white;
        text-align: left;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            grid-gap: 100px;
            font-size: 18px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            font-size: 18px;
            grid-gap: 30px;
        }
        @media only screen and (max-width: 1023px) {
            grid-gap: 35px;
            font-size: 18px;
            flex-direction: column;
        }

        &.dark {
            .description {
                color: #0A141C !important;
            }
        }

        .header-title {
            font-size: 46px;
            font-weight: normal !important;
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                font-size: 34px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 21px;
            }
        }

        .text-content {
            width: 45%;
            @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                width: 50%;
            }
            @media only screen and (max-width: 1023px) {
                width: 100%;
            }

            .title {
                margin-top: 44px;
                font-size: 21px;
                @media only screen and (max-width: 1024px) and (min-width: 768px) {
                    margin-top: 34px;
                }
                @media only screen and (max-width: 767px) {
                    margin-top: 21px;
                }

            }

            .description {
                margin-top: 10px;
                transition: all .4s $ease-out;

                p, h1, span, div {
                    font-family: 'Noto-Sans', 'Noto-Sans-georgian';
                    font-size: 21px;
                    line-height: 24px;
                    transition: all .4s $ease-out;
                }
            }
        }

        .image-container {
            width: 750px;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                width: 539px;
                min-width: 539px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                width: 458px;
                min-width: 458px;
            }

            @media only screen and (max-width: 1023px) {
                width: 100%;
            }

            .image {
                width: 100%;
            }
        }
    }

}
</style>
